<template>
  <v-app >
    <Slide right class="menu" :closeOnNavigation="true">
      <router-link v-show="$route.path !== '/'" to="/">
        <span>Home</span>
      </router-link>
      <router-link v-show="$route.path !== '/aboutus'" to="/aboutus">
        <span>O nama</span>
      </router-link>  
      <router-link v-show="$route.path !== '/eyebrows'" to="/eyebrows" alt="eyebrows">
      <span>Obrve</span>
      </router-link>
      <router-link v-show="$route.path !== '/lips'" to="/lips" alt="lips" class="dropdown-submenu">
      <span>Usne</span>
      </router-link>
      <router-link v-show="$route.path !== '/eyeliner'" to="/eyeliner" alt="eyeliner">
      <span>Eyeliner</span> 
      </router-link>
      <router-link v-show="$route.path !== '/philaser'" to="/philaser">
        <span>PhiLaser &amp; Nanoremoval</span>
      </router-link>
      <!-- <router-link to="/">
        <span>Nega lica</span>
      </router-link> -->
      <router-link v-show="$route.path !== '/phidrofacial'" to="/phidrofacial">
        <span>Phidrofacial &amp; <br> PhiBright</span>
      </router-link>
      <router-link v-show="$route.path !== '/manicure'" to="/manicure">
        <span>Manikir - Pedikir</span>
      </router-link>
      <!-- <router-link to="/microneedling">
        <span>Microneedling</span>
      </router-link>   -->
      <router-link v-show="$route.path !== '/contact'" to="/contact">
        <span>Kontakt</span>
      </router-link>  
    </Slide>
    <v-main>
      <v-slide-x-transition mode="out-in">
        <router-view />
      </v-slide-x-transition>
    </v-main>
  </v-app>
</template>

<script>
import { Slide } from 'vue-burger-menu'



export default {
  name: 'App',
  components: {
    Slide
  },

  data: () => ({
    items: [
      { title: 'Usne' },
      { title: 'Obrve' },
      { title: 'Eyliner' },
    ],
  }),
};
</script>


<style>
html,body{
  height: 100%;
  width: 100%;
  margin: 0;
}

.menu{
  z-index: 100;
  font-family: "IvyMode Lt";
  font-size: 25px;
  font-weight: 300;
  letter-spacing: 1px;
  color: #000000 !important;
}

* {
  box-sizing: border-box;
}

a:link {
  text-decoration: none;
  color: #fff0e7;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.arrow-btn{
    margin-top: 108px;
}

p {
  font-family: "IvyMode Lt";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

h1 {
  font-family: "IvyMode Semi Bd";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}


.menu .bm-burger-button {
  position: fixed;
  width: 28px;
  height: 21px;
  left: 36px;
  top: 36px;
  cursor: pointer;
}

.menu .bm-burger-bars {
  background-color: #fff !important;
}

.line-style {
  position: absolute;
  height: 4%;
  left: 0;
  right: 0;
}

.cross-style {
  position: absolute;
  top: 12px;
  right: 2px;
  cursor: pointer;
}
.menu .bm-cross {
  background: #000000;
}
.menu .bm-cross-button {
  height: 24px;
  width: 24px;
}

.menu .bm-menu {
  height: 100% !important; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Stay on top */
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255) !important; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
}

.menu .bm-overlay {
  background: rgba(0, 0, 0, 0.3) !important;
}

.menu .bm-item-list {
  margin-left: 10%;
  font-size: 20px;
}

.menu .bm-item-list > * {
  display: flex;
  text-decoration: none;
  padding: 0.7em;
  flex-direction: column;
}
.menu .bm-item-list > * > span {
  margin-left: 10px;
  font-weight: 700;
  color: rgb(0, 0, 0) !important;
}

.dropbtn {
  margin-left: 7px;
  font-family: "IvyMode Semi Bd";
  font-size: 20px;
  font-style: italic;
  letter-spacing: 1px;
  color: #000000;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  min-width: 160px;
  z-index: 1;
  color: #000000;
}

.dropdown-content a{
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.break{
    display: unset;
}

@media only screen and (max-width: 550px) {
    .break{
        display: none;
    }

    .menu .bm-item-list {
      margin-left: 10%;
      font-size: 16px;
    }
}

.grecaptcha-badge {
  display: none !important;
}

</style>