<template>
    <div class="mp-back">
        <v-container class="footer-holder" d-flex justify-space-between>
            <div class="footer-left">
                <h3 class="big-screen-title">Prepustite nam se danas</h3>
                <div class="about-btn">
                    <router-link to="/contact" class="hero-btn">Zakažite termin</router-link><span><img src="../assets/arrow-black.png" alt="arrow-right"></span>  
                </div>
                <h3 class="mobile-title">Prepustite nam se danas</h3>
                <div class="logo">
                    <span><a href="#"></a><img src="../assets/logo-footer.png" alt="logo"></span>
                </div>
            </div>
            <div class="footer-right">
                    <h3>zapratite nas</h3>
                <div class="social-holder">
                    <span class="social-i"><a href="https://instagram.com/norapmu?utm_medium=copy_link"><img src="../assets/instagram-icon.png" class="social-img" alt="facebook"><span class="social-text">instagram</span></a></span>
                        
                    <span class="social-f"><a href="https://www.facebook.com/norapmu"><img src="../assets/facebook-icon.png" class="social-img" alt="instagram"><span class="social-text">Facebook</span></a></span>
                </div>
                <div class="copyright">
                    <p>Copyright © Nora PMU 2021.</p>
                </div>
            </div>
        </v-container> 
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data: function() {
        return {}
    },    
}
</script>

<style scoped>
.mp-back{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff0e7;
    
}

.footer-left{
    display: inline-block;
    padding-top: 40px;
}

.footer-left h3{
    font-family: "IvyMode Lt";
    font-size: 27px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.82px;
    color: #101010;
}
.footer-right{
    padding-top: 40px;
    width: 350px;
}


.footer-right h3{
    font-family: Quicksand;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.94;
    text-transform: uppercase;
    color: #06070c;
}

.social-holder{
    margin-top: 31px;
    display: flex;
    flex-direction: row;
    margin-bottom: 98px;
   
}

.social-img{
    padding-right: 20px;
}

.social-i,
.social-f{
    
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: Quicksand;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: right;
    text-transform: uppercase;
    margin-right: 20px;

}

.social-i a,
.social-f a{
    text-decoration: none;
    color: #06070c;
}

.copyright{
    text-align: right;
}

.about-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    display: inline-block;
    margin: 31px 0 80px 0;
}

.about-btn a{
    font-family: "IvyMode Semi Bd";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.33px;
    color: #000000;
}

.about-btn span{
    margin-top: 4px;
    margin-left: 19px;
}

.mobile-title{
    display: none;
}

@media only screen and (max-width: 550px) {
    .footer-holder{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap-reverse;
        width: 100%;
    }

    .footer-left{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .footer-right{
        padding-top: 0;
        text-align: center;
        width: 100%;
    }

    .mobile-title{
        margin-top: 30px
        ;
        display: block;
    }

    .big-screen-title{
        display: none;
    }
    .about-btn{
        margin-bottom: 45px;
    }

    .copyright{
        text-align: center;
    }

    .social-holder{
        display: flex;
        justify-content: center;
        margin-bottom: 35px;
    }

    .social-i,
    .social-f{
        display: flex;
        justify-content: center;
        width: 7%;
    
    }

    .social-text{
        display: none;
    }

    .social-img{
        padding-right: 0;
    }
}
</style>