<template>
    <div class="offer">
        <v-container fluid class="offer-container">
            <div class="title-mobile">
                <h2 class="mobile-title">PERMANENTNA ŠMINKA</h2>
            </div>
            <v-layout row wrap>
                <v-flex cols v-for="(offer,idx) in offers" :key="offer.title">
                    <v-card flat style="background-color:transparent; border-radius:0" :style="calculateMargin(idx)">
                        <router-link :to="offer.link" class="offer-btn">
                            <v-img contain class="offer-img" :src="offer.image"></v-img> 
                            
                            <div class="offer-heading-container">
                                <div class="offer-txt" style="cursor:default;">{{ offer.title }}</div>
                                <img class="offer-arrow" src="../assets/arrow-big.png" alt="arrow-open-offer"/>
                            </div>
                        </router-link>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Offer',
    data: function() {
        return {

        offers: [
                { title: 'obrve', image: require("../assets/obrve.jpg"), link: '/eyebrows'},
                { title: 'usne', image: require("../assets/usne.jpg"), link: '/lips'},
                { title: 'eyeliner', image: require("../assets/eyeliner.jpg"), link: '/eyeliner'}
            ],
        }
    },
    methods: {
        calculateMargin(idx) {
            var topMargin = 60*idx;
            return {
                margin: `${topMargin}px 30px 0 30px`
            }
        }
    }
}
</script>


<style scoped>

.offer{
    background-color: #ffe7d8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0 80px 0;
}

.offer-txt{
    margin-top: 26px;
    font-family: IvyMode;
    font-size: 54px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0;
    color: #000000;
    text-transform: uppercase;
}

.offer-btn{
    text-decoration: none;
}

.offer-heading-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-content: center;
    align-items: baseline;
    padding: 0 23px;
}

.offer-arrow {
    width: 60.4px;
    height: 30.2px;
    object-fit: contain;
}

.offer-img {
    height: 500px;
    width: auto;
    background-size: contain;
}

.title-mobile{
    /* display: none; */
    text-align: center;
    margin-bottom: 20px;
    display: none;
}

.title-mobile{
    padding: 40px 0 20px;
}


/*Right arrow animation*/

@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
/* /right bounce */


/* assign bounce */
.offer-arrow {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
  float:right;
}

@media only screen and (max-width: 550px) {

    .offer{
        padding: 0 0 60px 0;
    }

    .mobile-title{
        font-family: Quicksand;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.97px;
    }
   
    .offer-txt{
        font-size: 26px;
    }

     .offer-img {
        height: 320px;
    }

    .offer-heading-container{
        padding: 0 48px;
    }

    .title-mobile{
        display: block;
    }

}

@media only screen and (max-width: 320px) {
    .offer-img {
        height: 320px;
    }
}

</style>