<template>
  <div class="home">
    <Hero :heading-text="'PHI STUDIO'" :heading-subtitle="'by Nora Jakovljević'" :cta-text="'ZAKAŽITE TERMIN'" background-url="hero.jpg" background-url-mobile="hero-mobile.jpg"></Hero>
    <HeadlineCopy src1="phi-laser.jpg" :heading-text="'Dobrodošli'" :heading-subtitle="'U našem salonu se izuzetno ceni estetika. Želimo da istaknemo prirodan i senzualan izgled svake od vas, tako da naglasimo vaše crte lica  i lepotu koju posedujete. Sa ciljem da od nas odete nasmejane i da vaš osmeh zatim uvek bude prisutan, posvećujemo se svakoj od vas sa posebnom pažnjom, uz veliku zahvalnost za poverenje koje nam ukazujete.'"></HeadlineCopy>
    <SingleImageComponent src="prvoklasna-usluga.jpg" order-type="IMAGE_RIGHT" :heading-text="'O NAMA'" :heading-subtitle="'Prvoklasna <br>usluga iz oblasti <br>permanentne šminke'" :cta-text="'Saznajte više'" cta-link="/aboutus"></SingleImageComponent>
    <Services></Services>
    <Offer></Offer>
    <SingleImageComponent src="philaser.jpg" order-type="IMAGE_LEFT" :heading-text="'UKLANJANJE NEPRAVILNOSTI'" :heading-subtitle="'PhiLaser &<br/> Nanoremoval'" :cta-text="'Saznajte više'" cta-link="/philaser"></SingleImageComponent>
    <FaceTreatment></FaceTreatment>
    <ManicurePedicure></ManicurePedicure>
    <Education></Education>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import HeadlineCopy from '@/components/HeadlineCopy.vue'
import SingleImageComponent from '@/components/SingleImageComponent.vue'
import Services from '@/components/Services.vue'
import Offer from '@/components/Offer.vue'
import FaceTreatment from '@/components/FaceTreatment.vue'
import ManicurePedicure from '@/components/ManicurePedicure.vue'
import Education from '@/components/Education.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'Home',
  components: {
    Hero,
    HeadlineCopy,
    SingleImageComponent,
    Services,
    Offer,
    FaceTreatment,
    ManicurePedicure,
    Education,
    Footer
  },
  data() {
    return {}
  }
}
</script>
