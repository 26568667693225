<template>
    <v-container fluid >
        <v-layout row class="frame">
            <v-flex data-aos="fade-up" d-flex v-if="orderType=='IMAGE_LEFT'" text-center xs12 md6>
                 <div class="about-img-frame">
                    <v-img :src="resolve_img_url(src)" alt="image"></v-img>
                 </div>
            </v-flex>
            <v-flex d-flex justify-center align-self-center text-left xs12 md6 >
                 <div data-aos="zoom-in" class="about-text" :class="{'text-centered': centerText}">
                    <p :style="{'color': textcolor}">{{headingText}}</p>
                    <h2 :style="{'color': textcolor}" v-html="headingSubtitle"></h2>
                    <div class="about-btn">
                        <router-link :to="ctaLink" :style="{'color': textcolor}">{{ctaText}}</router-link><span><img src="../assets/arrow-black.png" alt="arrow-right"></span>
                    </div>
                 </div>
            </v-flex>
             <v-flex data-aos="fade-down" d-flex v-if="orderType=='IMAGE_RIGHT'" text-center xs12 md6>
                 <div class="about-img-frame">
                    <v-img :src="resolve_img_url(src)" alt="image"></v-img>
                 </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'SingleImageComponent',
    // orderType - can be one of two strings
    //      IMAGE_LEFT - Will display image on left-hand side
    //      IMAGE_RIGHT - Will display image on right-hand side
    props: ["orderType", "headingText", "headingSubtitle", "ctaText", "ctaLink", "src", "framecolor", "textcolor", "centerText"],
    data: function() {
        return {

        }
    },
    methods: {
        resolve_img_url: function (path) {
            let images = require.context('../assets/', false, /\.png$|\.jpg$/)
            return images('./'+path)
        }
    }
}
</script>


<style scoped>

.frame{
    background-color: #fff0e7;
}

.text-centered {
    text-align: center;
}

.about-text p{
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.97px;
    color: #000000;
    margin-bottom: 25px;
}

.about-text h2{
    font-family: IvyMode;
    font-size: 72px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: 2px;
    color: #000000;
}

.about-img-frame{
    width: 100%;
    height: auto;
}

.about-btn{
    display: flex;
    justify-content: flex-start;
    align-items: left;
    height: auto;
    margin-top: 81px;
}

.about-btn a{
    font-family: IvyMode;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.33px;
    color: #000000;
}

.about-btn span{
    margin-top: 4px;
    margin-left: 19px;
}

@media only screen and (max-width: 1365px) {

}

@media only screen and (max-width: 800px) {

}

@media only screen and (max-width: 550px) {
    .about-text{
        text-align: center;
        padding: 60px 0;
    }

    .frame{
        display: flex;
        flex-direction: column-reverse;
    }

    .about-img-frame{
        width: 100%;
    }

    .about-text p{
        font-size: 16px;
    }

    .about-text h2{
        font-size: 36px;
        font-family: "IvyMode Lt";
    }

    .about-btn{
        justify-content: center;
    }

    .frame{
        height: auto;
        
    }
    
}

</style>