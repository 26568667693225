<template>
    <div class="services-info">
        <v-container d-flex flex-column  align-center justify-center>
            <div class="services-title">
                <h1>Usluge</h1>
            </div>
            <div class="arrow-btn">
                    <img src="../assets/arrow-down.png" alt="arrow down">
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Services',
    data: function() {
        return {}
    },
}
</script>

<style scoped>

.services-info{
    height: 100vh;
    background-image: url('../assets/usluge-slika.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.services-title{
    font-family: IvyMode;
    font-size: 70px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 6.06px;
    text-align: center;
    color: #fff0e7;
}

@media only screen and (max-width: 550px) {
    .services-info{
        background-image: url('../assets/usluge-slika-mobile.jpg')center center / cover
        ;
        height: auto;
    }
    .services-title{
        font-size: 44px;
        margin-top: 144px;
    }
}

</style>


