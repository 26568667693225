<template>
  <div class="back">
    <v-container>
      <div class="headline-holder">
        <h2 class="mobile-title" v-html="headingText"></h2>
        <div class="headline-img-holder">
          <div  data-aos="zoom-in-down" class="headline-img-girl">
            <v-img :src="resolve_img_url(src1)" class="headline-girl" alt="girl"></v-img>
          </div>
          <div  data-aos="zoom-in-up" v-if="src2" class="headline-image2">
            <v-img :src="resolve_img_url(src2)" alt="Phi Contour"></v-img>
          </div>
        </div>
        <div data-aos="zoom-in-down" class="headline-txt-holder">
          <h2 class="bigscreen-title" v-html="headingText"></h2>
          <div class="headline-subtitle-holder">
            <p v-html="headingSubtitle"></p>
          </div>
          <div class="btn-holder" v-if="ctaText">
              <div class="about-btn">
                <router-link to="/contact" class="flyIn lineThree hero-btn">{{ctaText}}</router-link><span><img src="../assets/arrow-black.png" alt="arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
    name: 'HeadlineCopy',
    // orderType - can be one of two strings
    //      IMAGE_LEFT - Will display image on left-hand side
    //      IMAGE_RIGHT - Will display image on right-hand side
    props: ["orderType", "headingText", "headingSubtitle", "ctaText", "src1", "src2"],
    data: function() {
        return {}
    },
    methods: {
        resolve_img_url: function (path) {
            let images = require.context('../assets/', false, /\.png$|\.jpg$/)
            return images('./'+path)
        }
    }
}
</script>


<style scoped>


.back{
  background-color: #ffe7d8;
  padding: 10px;
}

.headline-holder{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 60px 10px;
}


.headline-girl img{
  width: 20%;
}

.headline-txt-holder{
  margin-left: 59px;
}

.mobile-title{
  display: none;
}

.headline-txt-holder h2{
  font-family: "IvyMode Semi Bd";
  margin-bottom: 40px;
  font-size: 66px;
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: 2px;
  color: #101010;
}

.headline-txt-holder p{
  margin-bottom: 40px;
  font-family: Quicksand;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.94;
  color: #000000;
}

.headline-subtitle-holder{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline-img-holder{
  position: relative;
}

.headline-image2{
  position: absolute;
  bottom: 50px;
  left: -150px;
  background-color: transparent;
  width: 250px;
  height: 250px;
  padding:20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.btn-holder{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 84%;
}

.about-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    display: inline-block;
}

.about-btn a{
    font-family: "IvyMode Semi Bd";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.33px;
    color: #000000;
}

.about-btn span{
    margin-top: 4px;
    margin-left: 19px;
}

@media only screen and (max-width: 550px) {

  .headline-holder{
    height: 100%;
    width: auto;
    flex-wrap: wrap;
  }


  .headline-img-holder{
    display: flex;
    justify-content: center;
    width: 367px;
  }

  .mobile-title{
    display: block;
    margin-bottom: 23px;
    font-family: "IvyMode Semi Bd";
    font-size: 36px;
    font-weight: 600;
    line-height: 1.22;
    letter-spacing: 1px;
    text-align: center;
  }

  .headline-image2{
    width: 200px;
    height: 200px;
    bottom: 70px;
    left: 0;
  }

  .bigscreen-title{
    display: none;
  }

  .headline-img-girl{
    width: 60%;
  }

  .headline-txt-holder{
    margin-left: 0;
    justify-content: center;
  }

  .headline-txt-holder h2{
        font-size: 36px;
        text-align: center;
    }

    .headline-subtitle-holder{
      margin-top: 40px;
    }

    .headline-subtitle-holder p{
      line-height: 1.38;
    }
}

</style>