<template>
    <div class="hero-back" :style="{backgroundImage: 'linear-gradient(#00000081, #00000000) ,'+'url('+resolveImage(backgroundUrlMobile, backgroundUrlTablet, backgroundUrl)+')' }">
        <v-container>
            <div class="hero-info">
                <h1 class="flyIn lineOne hero-title ivymodesemibold">{{headingText}}</h1>
                <p class="flyIn lineTwo hero-subtitle ivymodelight" v-if="headingSubtitle">{{headingSubtitle}}</p>
                <router-link to="/contact" class="flyIn lineThree hero-btn">{{ctaText}}</router-link>
            </div>
                <div class="arrow-holder">
                    <div class="flyIn lineFour arrow"></div>  
                </div>
        </v-container>
    </div>
</template>


<script>
export default {
    name: 'Hero',
    // orderType - can be one of two strings
    //      IMAGE_LEFT - Will display image on left-hand side
    //      IMAGE_RIGHT - Will display image on right-hand side
    props: ["orderType", "headingText", "headingSubtitle", "ctaText", "src", "backgroundUrl","backgroundUrlTablet","backgroundUrlMobile"],
    data: function() {
        return {
            angle: '50',
            color1: 'red',
            color2: 'blue'
        }
    },
    methods: {
        resolveImage(mobilePath, tabletPath, defaultPath) {
             let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;             
             if(width<550 && mobilePath) {
                 return this.resolve_img_url(mobilePath)
             }
             if(width<800 && tabletPath) {
                 return this.resolve_img_url(tabletPath)
             }
             return this.resolve_img_url(defaultPath)
        },
        resolve_img_url: function (path) {
            let images = require.context('../assets/', false, /\.png$|\.jpg$/)
            return images('./'+path)
        }
    },    
    computed: {
        createBackgroundString() {
            return `linear-gradient(${this.angle}deg, ${this.color1}, ${this.color2})`;
        }
    },
}
</script>

<style scoped>

.hero-back{
    height: 100vh;
    background: linear-gradient(#00000081, #00000000), url('../assets/hero.jpg') center center / cover;
    display: flex;
    align-items: center;
    justify-content: center;
}


.hero-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    height: 100vh;
    width: auto;
}

.hero-title{
    font-family: ivymode, sans-serif;
    color: #fff0e7;
    font-size: 90px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 5.45px;
    text-align: center;
    margin-bottom: 28px;
}

.hero-subtitle{
    font-family: ivymode, sans-serif;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.7px;
    text-align: center;
    color: #fff0e7;
}

.hero-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 844px;
    height: 80px;
    opacity: 0.5;
    border: solid 1px #ffffff;
    text-align: center;
    margin-top: 92px;
    color: #fff0e7;
}


.ivymodelight {
    font-family: "IvyMode Lt";
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

.ivymodesemibold {
    font-family: "IvyMode Semi Bd";
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

.arrow-holder{
    position: relative;
}

.arrow,
.arrow:before {
  position: absolute;
  
}

.arrow {
  width: 40px;
  height: 40px;
  bottom: 26px;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  margin: -20px 0 0 -20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
}

.arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  top: 2%;
  /* margin: -10px 0 0 -10px; */
  border-left: none;
  border-top: none;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}

/* .flyIn {
    position:relative;
} */
.lineOne {
	transition-delay:.3s; 
	transition:.4s ease in;
	animation: txtFlyIn .3s linear;
}
.lineTwo {
	  transition-delay:.6s; 
	  transition:.6s ease in;
	  animation: txtFlyIn .6s linear;
}
.lineThree {
	  transition-delay:.9s; 
	  transition:.8s ease in;
	  animation: txtFlyIn .9s linear;
}

.lineFour {
		transition-delay:3s; 
		transition:2s ease in;
		animation: fadeIn 2s linear;
}

@keyframes fadeIn{
    0% { opacity:0;}
    100% { opacity:1;}
}
@keyframes txtFlyIn{
    0% { transform:translateX(-100%); }
    100% { transform:translateX(0%); }
}


@media only screen and (max-width: 1365px) {

}

@media only screen and (max-width: 800px) {

}

@media only screen and (max-width: 550px) {
    .hero-back{
        height: auto;
    }

    .hero-title{
        padding-top: 56px;
        font-size: 44px;
    }

    .hero-subtitle{
        font-size: 22px;
    }

    .hero-btn{
        width: 260px;
        height: 60px;
        margin: 32px 10px 38px;
        padding: 21px 38px 21px;
    }

    .arrow{
        width: 24px;
        height: 24px;
        bottom: 15px;
    }

    .arrow:before{
        width: 14px;
        height: 14px;
        top: 5%;
    }
    
}


</style>