<template>
    <div class="mp-back">
        <v-container d-flex  justify-center align-center flex-column>
            <div class="mp-title-holder">
                <h2>Manikir &amp; Pedikir</h2>
            </div>
            <div data-aos="flip-left" class="mp-image-holder">
                <div class="manicure-pic">
                    <img src="../assets/manikir.jpg" alt="Manicure">
                </div>
                <!-- <div class="pedicure-pic">
                    <img src="../assets/pedikir.jpg" alt="Pedicure">
                </div> -->
            </div>
             <div class="about-btn">
                <a href="/manicure">Saznajte više</a><span><img src="../assets/arrow-black.png" alt="arrow-right"></span>
            </div>
            <div class="arrow-btn">
                <img src="../assets/arrow-down-black.png" alt="arrow down">
            </div> 
        </v-container> 
    </div>
</template>


<script>
export default {
    name: 'ManicurePedicure',
    data: function() {
        return {}
    },
}
</script>

<style scoped>

.mp-back{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffe7d8;
    
}

.mp-title-holder{
    margin-top: 121px;
    margin-bottom: 50px;
}

h2{
    font-size: 66px;
    font-family: "IvyMode Lt";
    line-height: 70px;
    letter-spacing: 1.83px;
}

.mp-image-holder{
    width: 100%;
    text-align: center;
}

.pedicure-pic{
    display: inline-block;
    position: absolute;
    top: 13%;  
    right: 16%;
}

.pedicure-pic img{
    width: 100%;
}


.about-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    display: inline-block;
    margin:60px 0;
}

.about-btn a{
    font-family: "IvyMode Semi Bd";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.33px;
    color: #000000;
}

.about-btn span{
    margin-top: 4px;
    margin-left: 19px;
}

.arrow-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:30px 0 30px 0;
}

@media only screen and (max-width: 550px) {
    .mp-image-holder{
        width: 100%;
    }

    .manicure-pic img{
       width: 80%;
       
    }

    .pedicure-pic{
        width: 43%;
    }

    .pedicure-pic{
        right: 0;
    }

    .mp-title-holder{
        text-align: center;
        margin: 0 0 30px;
    }

    .mp-title-holder h2{
        font-size: 36px;
        line-height: 1.22;
    }

    .arrow-btn{
        display: none;
    }

    .about-btn{
        margin: 40px 0 0 0;
    }

    .mp-back{
        padding: 60px 0;
    }
}

</style>