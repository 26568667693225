<template>
<div>
    <div data-aos="zoom-in" data-aos-duration="3000" class="facetreatment-info-mobile">
        <p class="facetreatment-subtitle-mobile">TRETMANI ZA LICE</p>
        <h2 class="facetreatment-title-mobile">Phidrofacial & <br>Microneedling <br>Phi Bright</h2>
        <div class="about-btn">
            <a href="/phidrofacial">saznajte više</a><span><img src="../assets/arrow-light.png" alt="arrow-right"></span>
        </div>
    </div>
    <div class="hero-back">
        <v-container>
            <div data-aos="zoom-in" data-aos-duration="3000" class="facetreatment-box">
                <div class="facetreatment-info">
                    <p class="facetreatment-subtitle">TRETMANI  LICA</p>
                    <h2 class="facetreatment-title">Phidrofacial<br> &amp; <br>PhiBright</h2>
                    <div class="about-btn">
                        <a href="/phidrofacial">saznajte više</a><span><img src="../assets/arrow-light.png" alt="arrow-right"></span>
                    </div>
                </div>
            </div>        
            <div class="arrow-btn">
                <img src="../assets/arrow-down.png" alt="arrow down">
            </div> 
        </v-container>
    </div>
</div>
</template>


<script>
export default {
    name:'FaceTreatment',
    data() {
        return {}
    }
}
</script>


<style scoped>

.hero-back{
    height: 100vh;
    background-image: url('../assets/hero.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}


.facetreatment-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 20px;
}

.facetreatment-info-mobile{
    display: none;
}

.facetreatment-box{
    text-align: center;
}

.facetreatment-title{
    font-family: "IvyMode Semi Bd";
    color: #fff0e7;
    font-size: 66px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 5.45px;
    text-align: center;
    margin-bottom: 110px;
    margin-top: 39px;
}

.facetreatment-subtitle{
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.7px;
    text-align: center;
    color: #fff0e7;
}

.about-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    display: inline-block;
}

.about-btn a{
    font-family: "IvyMode Semi Bd";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.33px;
    color: #ffffff;
}

.about-btn span{
    margin-top: 4px;
    margin-left: 19px;
}


.arrow-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
}


@media only screen and (max-width: 550px) {

    .hero-back{
        height: 350px;
        width: auto;
        background: url('../assets/phidrofacial-mobile.jpg') center center / cover;

    }

    .facetreatment-info-mobile{
        display: block;
        background-color: #080808;
        color: #fff0e7;
        text-align: center;
        padding: 70px 0;
    }

    .facetreatment-subtitle-mobile{
        font-size: 16px;
    }

    .facetreatment-title-mobile{
        font-size: 36px;
        font-family: "IvyMode Semi Bd";
        margin: 20px 0 40px;
    }

    .facetreatment-info{
        display: none;
    }

    .arrow-btn{
        display: none;
    }
}
</style>