<template>
    <v-container fluid class="back">
        <v-layout row class="frame">
            <v-flex d-flex  justify-center align-self-center xs12 md6>
                 <div data-aos="zoom-in-right" data-aos-duration="3000" class="education-text">
                    <p>EDUKACIJE</p>
                    <h2>Učite sa <br>Norom <br>Jakovljević</h2>
                    <div class="nora-pic-mobile">
                        <img src="../assets/nora.jpg" alt="Noja Jakovljevic">
                     </div>
                    <div class="about-btn">
                        <a href="https://www.norajakovljevic.com/sr/">Kursevi</a><span><img src="../assets/arrow-light.png" alt="arrow-right"></span>
                    </div>
                 </div>
            </v-flex>
             <v-flex class="big-screen" text-center xs12 md6>
                 <div data-aos="zoom-out-left" data-aos-duration="3000" class="education-img-frame">
                     <div class="education-pic">
                        <img src="../assets/edukacije.jpg" alt="Education phi">
                     </div>
                     <div class="nora-pic">
                        <img src="../assets/nora.jpg" alt="Noja Jakovljevic">
                     </div>
                 </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>


<script>
export default {
    name: 'Education',
    data: function() {
        return {}
    },
}
</script>

<style scoped>

.back{
    background-color: #080808;
    /* padding: 98px 0 98px 0; */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.education-text{
    color: #ffe7d8;
}

.education-text h2{
    font-family: "IvyMode Semi Bd";
    font-size: 66px;
    line-height: 77px;
    letter-spacing: 1.83px;
    color: #ffe7d8;
    margin-top: 31px;
    margin-bottom: 118px;

}

.education-img-frame{
    position: relative;    
}

.nora-pic{
    position: absolute;
    top: 40px;
    left: 0;
}

.nora-pic-mobile{
    display: none;
}

.nora-pic-mobile img{
    width: 100%;
}

.about-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    display: inline-block;
    margin-top: 67px;
}

.about-btn a{
    font-family: "IvyMode Semi Bd";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.33px;
    color: #ffe7d8;
}

.about-btn span{
    margin-top: 4px;
    margin-left: 19px;
}

@media only screen and (max-width: 1365px) {
    .education-pic{
        display: none;
    }
}

@media only screen and (max-width: 800px) {

}

@media only screen and (max-width: 550px) {

    .back{
        height: 100%;
    }

    .big-screen{
        display: none;
    }

    .nora-pic-mobile{
        text-align: center;
        display: block;
        width: 70%;
        margin: 0 auto;
    }

    .education-text{
        text-align: center;
    }

    .education-text h2{
        font-size: 36px;
        line-height: 1.22;
        margin-top: 30px;
        margin-bottom: 42px;
    }

    .education-text p{
        margin-top: 60px;
    }


    .about-btn{
        margin-bottom: 60px;
    }
    
}


</style>
